<template>
  <div v-if="$store.category" id="event">
    <vue-headful
            :title="$store.metadata.title + ' | Edgeryders Events'"
            :description="getDescription($store.category.description)"
        />
    <Header :data="header" :meta="$store.metadata" />
    <Main>
  <Sidebar type="news" :event="$store.metadata.event" />
 
     <div class="mx-auto md:w-2/3 pb-10">
       <Content v-if="$store.views" :views="$store.views"  />
     </div>

    </Main>


 </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar'
import Header from '@/components/Header.vue'
import Content from '@/components/Content.vue'
export default {
  name: 'Event',
  data: function() {
    return {
      post: null
    };
  },
  methods: {
    
  },
  components: {
    Sidebar,
    Header,
    Content
  },
  created() {
    window.scrollTo(0,0);
    this.getNews(this.$route.params.id);
    this.getAirtable('appmxTB6O0CZopGfT');
    this.$store.blob.active = true;
    this.$store.blob.start = false;
    this.$store.type = 'news';
  }
}
</script>

<style lang="scss">

main {
  @apply flex mx-auto;
  width: 90%;
  max-width: 1280px;
}



</style>
