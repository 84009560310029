<template>
  <div :style="$store.style" class="news_sidebar">
    <div class="rounded-lg pr-6 pb-2 mb-10">
      <h3 class="category" @click="goTo('project', $store.category.slug)">
        {{ $store.category.name }}
      </h3>
      <div
        class="mb-6 text-base text-gray-600"
        v-if="$store.category && $store.category.description"
      >
        <p class="mb-4 pr-4">
          {{ getDescription($store.category.description) }}
        </p>
      </div>
    </div>

    <h3>About this post</h3>
    <p class="pr-10">
      Published on
      <a :href="'https://edgeryders.eu/t/' + $store.json.id"
        >{{ $store.metadata.date | dateShort }} at
        {{ $store.metadata.date | timeShort }}</a
      >
      <br />There are
      <a :href="'https://edgeryders.eu/t/' + $store.json.id"
        >{{ $store.json.posts_count }} comments</a
      >.
    </p>
    <!-- <h4>Links mentioned</h4>

<div class="article_links">
<a v-for="(item, index) in $store.json.details.links" :key="index" :href="item.url">{{item.title || item.url}}</a>
</div> -->
    <div class="my-6">
      <h3>Share this post</h3>
      <div class="flex mt-3">
        <div class="share" @click="shareLink('twitter')">
          <span class="twitter"></span>
        </div>
        <div class="share" @click="shareLink('facebook')">
          <span class="facebook"></span>
        </div>
        <div class="share" @click="shareLink('linkedin')">
          <span class="linkedin"></span>
        </div>
        <div class="share" @click="shareLink('email')">
          <span class="email"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    event: String,
  },
  methods: {
    shareLink(type) {
      var link = window.location;
      var social_link;
      if (type == "twitter") {
        social_link =
          "https://twitter.com/intent/tweet?url=" +
          link +
          "&text=" +
          this.$store.json.title;
        window.open(
          social_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=no,status=yes"
        );
      }
      if (type == "facebook") {
        social_link = "https://www.facebook.com/sharer/sharer.php?u=" + link;
        window.open(
          social_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=no,status=yes"
        );
      }
      if (type == "linkedin") {
        social_link =
          "https://www.linkedin.com/shareArticle?mini=true&url=" +
          link +
          "&summary=" +
          this.$store.json.title;
        window.open(
          social_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=no,status=yes"
        );
      }
      if (type == "email") {
        social_link =
          "mailto:?body=" + link + "&subject=" + this.$store.json.title;
        window.location = social_link;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sidebar {
  flex-shrink: 0;
  width: 450px;
  @apply mr-8 text-left text-lg pr-6;
  a {
    color: var(--color);
    @apply font-bold underline;
  }
}
h3.category {
  &:hover {
    cursor: pointer;
    @apply underline;
  }
}
h3 {
  margin: 0 0 0;
  @apply font-bold text-2xl mb-3 pb-3 border-b tracking-tight;
  color: var(--color);
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.article_links {
  width: 90%;
  white-space: wrap;
  a {
    @apply block mb-4;
    width: 80%;
  }
}

p.timezone {
  background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='globe-europe' class='svg-inline--fa fa-globe-europe fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 496 512'%3E%3Cpath fill='black' d='M248 8a248 248 0 100 496 248 248 0 000-496zm200 248c0 23-4 44-11 64h-20c-4 0-9-1-12-4l-31-33c-5-5-5-12 0-17l12-12v-9c0-3-1-6-3-8l-10-9c-2-2-5-4-8-4h-16a11 11 0 01-8-19l10-9c2-2 5-4 8-4h32c6 0 11-5 11-11v-9c0-7-5-12-11-12h-37c-9 0-16 8-16 16v5c0 7-4 13-11 15l-31 11c-4 1-6 4-6 7v2c0 5-3 8-8 8h-16c-4 0-8-3-8-8s-3-8-8-8h-3c-3 0-6 2-7 5l-10 18c-2 6-8 9-14 9h-22c-9 0-16-7-16-16v-25c0-4 2-8 5-11l20-20c4-5 7-11 7-18 0-3 2-6 5-8l40-13c2 0 4-1 5-3l27-26c2-2 3-5 3-8 0-7-5-12-11-12h-21l-16 16v8c0 5-4 8-8 8h-16c-4 0-8-3-8-8V84c0-2 1-5 3-6l29-22h6c110 0 200 90 200 200zM130 149c0-3 1-6 3-8l26-25c2-2 5-4 8-4 6 0 11 5 11 12v16c0 3-1 6-3 8l-10 9c-2 2-5 3-8 3h-16c-6 0-11-5-11-11zm128 307v-8c0-8-7-16-16-16h-20c-11 0-27-5-35-11l-23-17c-11-9-18-22-18-37v-23c0-16 9-31 22-39l43-26c7-4 15-7 24-7h31c11 0 21 4 29 11l44 37h18c8 0 16 4 22 10l18 17c3 4 8 5 13 5h13c-32 59-94 100-165 104z'/%3E%3C/svg%3E")
    no-repeat 0 50%;
  background-size: 20px;
  padding-left: 27px;
  @apply my-2 mb-4 font-bold;
}

a.register {
  @apply rounded-full px-4 py-2 inline-block my-2 text-base font-bold;
  border: 2px solid var(--color);
}
.share {
  @apply flex text-base items-center mr-2 inline-block;
  &:hover {
    cursor: pointer;
  }
  span {
    width: 35px;
    height: 35px;
    @apply inline-block;
    &.twitter {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='244.2' width='300'%3E%3Cpath d='M95 243A173 173 0 00269 61c11-8 22-19 30-31-11 5-23 8-35 9 13-7 22-19 27-34-12 7-25 13-39 15a61 61 0 00-104 56C97 74 52 49 22 12a61 61 0 0019 82c-10 0-20-3-28-8v1c0 30 21 54 49 60a61 61 0 01-28 1c8 24 31 42 58 42a123 123 0 01-91 26c27 17 59 27 94 27' fill='%23fff'/%3E%3C/svg%3E")
        #1da1f2 no-repeat;
      background-size: 50% !important;
      background-position: center 55% !important;
    }
    &.facebook {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fab' data-icon='facebook-f' class='svg-inline--fa fa-facebook-f fa-w-10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23fff' d='M279 288l14-93h-89v-60c0-25 13-50 53-50h40V6s-37-6-72-6c-73 0-121 44-121 125v70H23v93h81v224h100V288z'/%3E%3C/svg%3E")
        #4767aa no-repeat;
      background-size: 29% !important;
      background-position: 48% 52% !important;
    }
    &.linkedin {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fab' data-icon='linkedin-in' class='svg-inline--fa fa-linkedin-in fa-w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='white' d='M100 448H7V149h93zM54 108C24 108 0 84 0 54a54 54 0 01108 0c0 29-25 54-54 54zm394 340h-93V302c0-34 0-79-48-79s-56 38-56 77v148h-93V149h90v41h1c12-24 43-49 88-49 94 0 111 62 111 143v164z'/%3E%3C/svg%3E")
        RGBA(21, 105, 191, 1) no-repeat;
      background-size: 34% !important;
      background-position: 48% 52% !important;
    }
    &.email {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='at' class='svg-inline--fa fa-at fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' stroke='black' stroke-width='20' d='M256 8a248 248 0 10135 456c12-8 15-25 6-36l-10-12c-8-9-21-12-32-5A184 184 0 0172 256c0-101 83-184 184-184 100 0 184 58 184 160 0 39-21 80-58 84-18-1-17-13-14-30l24-121c3-15-9-29-24-29h-45a14 14 0 00-13 12c-15-18-41-22-60-22-75 0-138 63-138 152 0 65 37 106 96 106 27 0 57-16 75-39 10 34 41 34 71 34 109 0 150-71 150-147C504 96 394 8 256 8zm-22 304c-22 0-36-15-36-40 0-45 31-73 59-73 22 0 35 15 35 41 0 45-33 72-58 72z'/%3E%3C/svg%3E")
        no-repeat #000;
      background-size: 50% !important;
      background-position: center 55% !important;
    }
  }
}

.news_sidebar {
  width: 90%;
  h3 {
    @apply text-xl;
  }
  p {
    color: rgba(0, 0, 0, 0.7);
    @apply text-base tracking-tight leading-normal;
  }
}
</style>
