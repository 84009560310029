<template>
  <aside class="sidebar" :style="$store.style">
    <event v-if="type == 'event'" :event="event"/>
    <news v-if="type == 'news'" :event="event"/>
  </aside>
</template>

<script>
import event from './event.vue'
import news from './news.vue'
export default {
  name: 'Sidebar',
  props: {
    event: String,
    type: String
  },
  components: {
    event,
    news
  },
  methods: {
    shareLink(type) {
      var link = window.location;
      var social_link;
      if (type == 'twitter') {
        social_link = 'https://twitter.com/intent/tweet?url=' + link + '&text=' + this.$store.json.title;
        window.open(social_link, '_blank', 'location=yes,height=570,width=520,scrollbars=no,status=yes');
      }
      if (type == 'facebook') {
        social_link = 'https://www.facebook.com/sharer/sharer.php?u=' + link;
        window.open(social_link, '_blank', 'location=yes,height=570,width=520,scrollbars=no,status=yes');
      }
      if (type == 'linkedin') {
        social_link = 'https://www.linkedin.com/shareArticle?mini=true&url=' + link  + '&summary=' + this.$store.json.title;
        window.open(social_link, '_blank', 'location=yes,height=570,width=520,scrollbars=no,status=yes');
      }
      if (type == 'email') {
        social_link = 'mailto:?body=' + link + '&subject=' + this.$store.json.title;
        window.location = social_link;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sidebar {
  flex-shrink: 0;
  width: 450px;
  @apply mr-8 text-left text-lg px-6;
}
</style>
